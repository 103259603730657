import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const Nav = styled.div``

const List = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
`

const Navigation = () => {
  const data = useStaticQuery(graphql`
    query NavigationQuery {
      site {
        siteMetadata {
          navigation {
            isExternal
            display
            title
            url
          }
        }
      }
    }
  `)

  const { navigation } = data.site.siteMetadata
  return (
    <Nav>
      <List>
        {navigation.map((item) => {
          if (item.display) {
            if (item.isExternal) {
              return (
                <li key={item.title}>
                  <a
                    rel="noopener noreferrer"
                    target="_BLANK"
                    style={{
                      textDecoration: `none`,
                      padding: `5px 0px`,
                      boxShadow: `none`,
                      marginRight: "15px",
                    }}
                    href={item.url}
                  >
                    {item.title}
                  </a>
                </li>
              )
            } else {
              return (
                <li key={item.title}>
                  <Link
                    activeStyle={{ borderBottom: `1px solid #1abc9c` }}
                    style={{
                      boxShadow: `none`,
                      padding: `5px 0px`,
                      marginRight: "15px",
                    }}
                    to={item.url}
                  >
                    {item.title}
                  </Link>
                </li>
              )
            }
          }
          return true
        })}
      </List>
    </Nav>
  )
}

export default Navigation
