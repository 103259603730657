import React from "react"
import { graphql } from "gatsby"

import Navigation from "../components/navigation"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"

const Form = styled.span`
  input {
    width: 100%;
    margin: 8px 0;
    display: block;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 3px #ddd;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  button {
    width: 100%;
    text-align: center;
    background-color: #1abc9c;
    color: white;
    padding: 12px 20px;
    margin: 14px 0 8px 0;
    display: inline-block;
    border: none;
    box-shadow: inset 0 1px 3px #ddd;
  }
`

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const social = data.site.siteMetadata.social

  return (
    <Layout location={location} title={siteTitle} social={social}>
      <Seo title="Post Workshop Form" />
      <Navigation />
      <h1>Post Workshop Form </h1>
      <Form>
        <form
          name="post-workshop"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          action="/thanks"
        >
          <input type="hidden" name="form-name" value="post-workshop" />
          <p style={{ display: "none" }}>
            <label>
              Don’t fill this out if you’re human: <input name="bot-field" />
            </label>
          </p>
          <p>
            <label>
              Name <input type="text" name="name" />
            </label>
          </p>
          <p>
            <label>
              Email <input type="email" name="email" />
            </label>
          </p>
          <p>
            <label>
              JsFiddle Link <input type="text" name="code" />
            </label>
          </p>
          <p>
            <button type="submit">Send</button>
          </p>
        </form>
      </Form>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        social {
          title
          url
        }
      }
    }
  }
`
